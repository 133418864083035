import React from 'react'
import { Container, Row } from 'reactstrap'

import PageTemplate from '../components/pageTemplate'

let Privacy = () => (
    <PageTemplate title="プライバシーポリシー">
        <Container className="py-5">
            <Row>
                <h2 className="text-left mb-4">個人情報保護方針</h2>
                <p>
                    株式会社Geek Station（以下、当社）は、幅広い分野（モバイル系・オープン系・汎用機系）でのシステム開発・運営事業に携わっております。
                    当社は、これらの事業を行う上で、個人情報を保護することの重要性と社会的責任を認識し、業務上取り扱うすべての個人情報について、適正な取扱いと厳正な保護に努め、
                    個人情報保護基本方針を以下のとおり定め、全従業員（役員、パートタイマー、派遣労働者等を含む）はこれを遵守していきます。
                </p>
                <ol className="policy-list mb-5">
                    <li>当社は、個人情報保護に関する法令、国が定める指針、その他の規範を遵守します。</li>
                    <li>当社は、個人情報の管理責任者を置き、個人情報を適切に管理する体制を確立するとともに、当社の従業者への周知・遵守徹底に努めます。</li>
                    <li>当社は、個人情報の利用目的を必要な範囲内で明確に定め、事業の内容及び規模を考慮した適切な個人情報の取得・利用及び提供を行い、特定された利用目的の達成に必要な範囲を超えた取扱いを行なわないための適切な措置を講じます。</li>
                    <li>当社は、個人情報を正確かつ最新の状態に保つとともに、個人情報の漏えい、毀損又は滅失などの防止及び是正に努めます。</li>
                    <li>当社は、個人情報の取扱いに関して、本人からのお問い合わせ、苦情、開示、訂正及び削除等のご請求につきまして誠実かつ迅速に対応いたします。</li>
                    <li>当社は、個人情報保護に関する規程や行動規範を定め、個人情報の保護に取り組みます。その取り組みが確実に実施できているか定期的に確認、監査及び見直しを行うことにより、継続的に改善いたします。</li>
                </ol>
                <div className="policy-afterword">
                    制定日: 2020年4月20日<br />
                    株式会社Geek Station<br />
                    代表取締役　林田 昇大
                </div>
            </Row>
        </Container>
    </PageTemplate>
)

export default Privacy
